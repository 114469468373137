.icono-globo-base-outermost {
}


.icono-marker-pin-container > img {
  height: 45px;
  width: 45px;
}

.icono-marker-bandera-usuario-container {
  margin-top: -16px !important ;
}

.icono-marker-bandera-usuario-container svg {
  color: var(--verde-claro);
}

.icono-globo-container {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icono-globo-check-interno {
  position: absolute;
  left: 19px;
  top: 19px;
  background-color: green;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 300;
}

.icono-globo-cuenta-interna {
  position: absolute;
  left: 15px;
  top: 19px;
  background-color: green;
  width: 26px;
  height: 18px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #4d4d4c;

  z-index: 300;
}

.itinerario-popup .itinerario-columna1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.itinerario-popup .itinerario-columna2 {
  display: flex;
  flex-direction: column;
  justify-content: left;

  padding-left: 9px;
  border-left: black 1px solid;
  margin-left: 9px;
}

.itinerario-popup .itinerario-columna-unique {
  display: flex;
  flex-direction: column;
  justify-content: left;

  margin-left: 9px;
}

.itinerario-popup .itinerario-popup-orden {
  font-size: 30px;
  text-align: center;
  line-height: 1.1;
}

.itinerario-popup .itinerario-columna-container {
  display: flex;
  flex-direction: row;
  /*margin: 8px 10px*/
}
