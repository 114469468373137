.modal-transparent {
    --background: rgba(0, 0, 0, 0.5) !important;
}
/* Transparencia total para el modal*/
.modal-transparent-full {
  --background: rgba(0, 0, 0, 0) !important;
  --box-shadow: none;
  border: 0 !important;
  box-shadow: none !important;
}

.modal-transparent-full .modal-wrapper {
  --box-shadow: none;
}

