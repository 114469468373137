:root {
  --naranja-abi: #ffa300;
  --azul-abi: #303ab2;
  --azul-fabricante: #4a52b2;
  --gris-oscuro-abi: #4e4e4e;
  --gris-claro-abi: #c6c6c6;
  --gris-claro: #efefef;
  --gris-oscuro: #4e4e4e;
  --gris-mas-claro: #f7f7f7;
  --rojo-abi: #fc4c02;
  --naranja-transportista: #fc4c02;
  --verde-claro: #96ce50;
  --danger: #e10600;
  --success: #96ce4e;
  --oro: #ffa00b;
  --gray-custom: #d0d1d0;
}

ion-icon {
  pointer-events: none;
}

.pt-10 {
  padding-top: 70px;
}

.pt-12 {
  padding-top: 80px;
}

.pt-14 {
  padding-top: 100px;
}

.pt-15 {
  padding-top: 105px;
}

.pt-16 {
  padding-top: 120px;
}

.pt-18 {
  padding-top: 163px;
}

.checkbox-custom-orange {
  --background-checked: #ff4b00 !important;
  --border-color-checked: #ff4b00 !important;
}

.modal-imagen-detalle {
  --height: 400px;
}

.modal-imagen-grande {
  --height: 70%;
  --width: 900px;
}

.max-min-img-detalle-modal {
  max-height: 300px !important;
  max-width: 400px !important;
}

.imagen-registro-header-pagina > img {
  width: 100px;
  height: 100px;
}

.imagen-registro-header-pagina > span {
  max-width: 300px;
  text-align: center;
}

.btn-font-sm {
  font-size: 14px !important;
}

.uppy-Webcam-video {
  width: 480px !important;
  height: 360px !important;
}

.uppy-Webcam-buttonContainer {
  text-align: center !important;
}
.profile-pic-container {
  border: 1px solid;
  border-radius: 100%;
  vertical-align: middle;
  display: inline;
  padding: 7px 5px;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.form-check {
  padding-left: 0;
}

.form-check input[type='checkbox'] {
  display: none;
}

.toast.error {
  background: #fb3a3a;
  color: #fff;
}

.toast.success {
  background: #06d79c;
  color: #fff;
}

.select2-container .select2-selection--single {
  height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 38px;
}

.rdt_Table *,
.rdt_Table .rdt_TableCell {
  font-size: 1rem !important;
  font-weight: 300;
}

.rdt_Pagination * {
  font-size: 1rem !important;
  font-weight: 400;
}

.rdt_Table .rdt_TableHeadRow * {
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.rdt_Table .rdt_TableHeadRow {
  border-top: 1px solid #e8eef3;
  border-bottom: 2px solid #e8eef3;
}

.rdt_Table .rdt_TableRow {
  border: none !important;
}

.rdt_Table .rdt_TableCell {
  border-top: 1px solid #dee2e6;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
}

.rdt_Table .rdt_TableBody .rdt_TableRow:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.react-tel-input .form-control {
  width: 100%;
  border: 1px solid #e9ecef;
}

.react-tel-input .flag-dropdown {
  border: 1px solid #e9ecef;
}

#footer .scroll-to-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #4551b5;
  color: #fff;
  padding: 5px 0;
  cursor: pointer;
}

ion-modal .modal-shadow {
  pointer-events: none;
}

body {
  background-color: #eaeded;
}

.debug-box {
  position: absolute;
  background: yellow;
  color: black;
  left: 10px;
  top: 5px;
  z-index: 999;
  padding: 2px;
  border-radius: 5px;
  font-size: 8px;
}

.virtual-scroll-container {
  display: flex;
  flex-direction: column;
}

.pasos-registro-container {
  flex-grow: 1;
  align-self: center;
  max-width: 100%;
  overflow: hidden;
}

.mensaje-permiso .mensaje-botones {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0;
}

.mensaje-permiso {
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px 2px;
  border-radius: 10px;
  border: 1px solid #ccc;
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 99999;
}

.imagen-perfil-container {
  height: 140px;
  width: 140px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.imagen-perfil-container img {
  border: 2px solid #ff4400;
  height: 140px;
  width: 140px;
  border-radius: 140px;
  object-fit: cover;
}

img.imagen-perfil {
  object-fit: cover;
}

.icono-header {
  height: 40px;
  width: 40px;
}

.filter-invert-imagen {
  filter: brightness(0) invert(1);
}
.centrado-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.color-fuente-naranja-abi {
  color: var(--naranja-abi);
}
.fuente-azul-abi {
  color: #303ab2ff !important;
}
.background-color-naranja-abi {
  background-color: var(--naranja-abi);
}
.background-color-fabricante {
  background-color: var(--azul-fabricante);
}

.background-color-darkorange {
  background-color: darkorange;
}

.rating {
  font-weight: bold;
}

.rating svg {
  margin-top: -3px;
  width: 18px;
  height: 18px;
  margin-left: 1px;
}

.abi-page-wrapper.fabricante {
  padding-top: 104px; /* alto del header */
}

.abi-page-content {
  overflow-y: hidden;
  overflow-x: hidden;
  flex-grow: 1;
  height: 100%;
}

.tranportista-double-header-wrapper {
  background-color: white;
}

.footer-vista-app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #efefef;
  margin-bottom: env(safe-area-inset-bottom);
}

.modal-info.info-modal-2lines {
  --min-height: 100px;
  --height: 10vh;
}

.info-modal-3lines {
  --min-height: 120px;
  --height: 15vh;
}

.info-modal-4lines {
  --min-height: 140px;
  --height: 20vh;
}

.info-modal-confirm {
  --height: 24vh;
  --min-heigth: 200px;
}

.ver-mas-listado {
  margin-top: 30px;
  color: var(--rojo-abi);
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-outline-transportista {
  border-radius: 5px;
  border: 1px solid var(--naranja-transportista);
  background-color: white;
  color: var(--naranja-transportista);
  height: 45px;
  width: 100%;
}
.btn-outline-verde-abi {
  border-radius: 5px;
  border: 1px solid var(--success);
  background-color: white;
  color: var(--success);
  height: 45px;
  width: 100%;
}
.btn-principal-transportista {
  border-radius: 3px;
  background-color: var(--naranja-transportista);
  color: white;
  height: 45px;
  width: 100%;
}

.btn-outline-gris {
  border-radius: 5px;
  border: 1px solid var(--gris-oscuro);
  background-color: white;
  color: var(--gris-oscuro);
  height: 45px;
  width: 100%;
}

.no-favorito-corazon {
  color: var(--gris-claro-abi);
  max-height: 50px;
}
.no-favorito-corazon:hover {
  color: var(--rojo-abi);
}
.favorito-corazon {
  color: var(--rojo-abi);
}
.container-tarjeta-producto .icn-favoritos.favorito-corazon svg path {
  fill: var(--rojo-abi) !important;
}

.button-comprar-carrito-fav {
  border: 1px solid var(--naranja-abi);
  color: var(--naranja-abi);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  background-color: #fff;
}

.icono-add {
  color: var(--gris-claro-abi);
}
.icono-add:hover {
  color: green;
}
.icono-remove {
  color: var(--gris-claro-abi);
}
.icono-remove:hover {
  color: var(--rojo-abi);
}

.toggle-green {
  --background: #efefef;
  --background-checked: #efefef;
  --handle-background-checked: #28991f;
}

.footer-carousel-productos {
  color: #fc4c02;
  color: white;
  padding: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  background-color: var(--azul-abi);
  border-radius: 10px;
  font-weight: 400;
  margin-bottom: 20px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

@media screen and (max-width: 500px) {
  .button-comprar-carrito-fav {
    color: var(--naranja-abi);
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
  }

  .footer-vista-app,
  .footer-app {
    background-color: #efefef;
  }
}

@media screen and (min-width: 501px) {
  .button-comprar-carrito-fav {
    border: 1px solid var(--naranja-abi);
    color: var(--naranja-abi);
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    background-color: #fff;
  }
}

/* Remove default checkbox */
.form-group [type='checkbox']:not(:checked),
.form-group [type='checkbox']:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

.cpointer,
a {
  cursor: pointer;
}

.page-wrapper .container-lg {
  padding: 15px;
}

.swiper-container-horizontal > .swiper-scrollbar {
  height: 3px !important;
}

#cantidad-agregar,
.cantidad-agregar {
  position: absolute;
}

#cantidad-agregar:before,
.cantidad-agregar:before {
  content: attr(data-cantidadagregar);
  display: block;
}

.sendtocart {
  display: block;
  animation: xAxis 0.5s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
}

.sendtocart:before {
  animation: yAxis 0.5s alternate forwards cubic-bezier(1, 0.44, 0.84, 0.165);
}

#icon-carrito.shake {
  animation: shakeCart 0.4s ease-in-out forwards;
}

@keyframes xAxis {
  100% {
    transform: translateX(calc(125px));
  }
}

@keyframes yAxis {
  100% {
    transform: translateY(calc(-50vh));
  }
}

@keyframes shakeCart {
  25% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}

#release-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  align-self: stretch;
  margin-top: 1rem;
  color: #afafaf;
  font-size: 0.825rem;
}

.text-bold {
  font-weight: bold;
}

/* React select */
div[class*='css-'][class*='-container'] div[class*='css-'][class*='-control'] {
  border: 1px solid #e9ecef !important;
}

.form-control,
.form-control:focus {
  color: #4c4c4c;
}

.form-control::placeholder {
  color: #dedbe2;
}

.btn-abi,
.btn-abi:hover {
  background-color: var(--naranja-abi);
  color: #fff;
}
