.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner-loading-app {
    align-self: center;
    width: 10rem;
    height: 10rem;
    margin-top: 5rem;
    animation: change-color 5s linear infinite;
}

@keyframes change-color {
    0% {
        color: #FFA300;
    }

    50% {
        color: #FC4C02;
    }

    100% {
        color: #4A52B2;
    }
}